import { SUB_NAVIGATION_LINKS } from 'utils/constants';

export const useDropDownMenu = ({
  isLightTheme,
  isDropMenuOpened,
  isPageScrolledDown,
  slug,
  closeMobileMenu,
  closeDropDownMenu,
}) => {
  const subNavigationLinks = (SUB_NAVIGATION_LINKS[slug] || []);

  const handleOnLinkClick = (subMenuLinkSlug) => () => {
    if (subMenuLinkSlug) {
      closeMobileMenu();
      closeDropDownMenu();
    }
  };

  return {
    slug,
    isLightTheme,
    isDropMenuOpened,
    isPageScrolledDown,
    handleOnLinkClick,
    subNavigationLinks,
    closeMobileMenu,
  };
};
